import { FC, useState } from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { Layout } from '@/components/layout'
import { Spinner } from '@/components/spinner/spinner'

import { AuthController } from './libs/components/auth-controller'

const App: FC = () => {
  const [isAppLoaded, setIsAppLoaded] = useState(false)

  return (
    <Router>
      {isAppLoaded && <Layout />}
      {!isAppLoaded && <Spinner absoluteCentered />}
      <ToastContainer theme="dark" />
      <AuthController setIsAppLoaded={setIsAppLoaded} />
    </Router>
  )
}

export { App }
