import { Outlet } from 'react-router-dom'

import { Grid } from '@mui/material'

import { Overview } from './libs/components/overview'
import { Sidebar } from './libs/components/sidebar/sidebar'

const Dashboard = () => {
  return (
    <Grid flexDirection="row" display="flex" height="100%">
      <Sidebar>
        <Overview />
      </Sidebar>
      <Outlet />
    </Grid>
  )
}

export { Dashboard }
