import { FC, PropsWithChildren } from 'react'

import { Grid } from '@mui/material'
import cn from 'classnames'

import { ButtonCopy, Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProps = PropsWithChildren & {
  title: string
  withCopy?: boolean
  info?: string | React.ReactElement
  className?: string
  infoClassName?: string
  infoAttachment?: React.ReactNode
}

const InfoItemWithLabel: FC<TProps> = ({
  title,
  withCopy,
  className,
  infoClassName,
  info,
  children,
  infoAttachment,
}) => {
  return (
    <Grid className={cn(styles.container, className)}>
      <Grid className={styles.header}>
        <Typography textColor="gold" variant="h5" className={styles.title}>
          {title}
        </Typography>
      </Grid>
      <Grid className={styles.content}>
        {children || (
          <Grid display="flex" alignItems="center">
            <Typography textColor="white" variant="h5" className={cn(styles.info, infoClassName)}>
              {info}
            </Typography>
            {withCopy && typeof info === 'string' && <ButtonCopy value={info} />}
            {infoAttachment}
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export { InfoItemWithLabel }
