import { FC } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'

import Modal from '@mui/material/Modal'
import cn from 'classnames'

import { ButtonIcon } from '@/libs/common'
import { AppRoute, IconName } from '@/libs/enums'

import styles from './styles.module.scss'

const ModalPage: FC = () => {
  const navigate = useNavigate()

  const handleClose = () => {
    navigate(`${AppRoute.DASHBOARD}`)
  }

  return (
    <Modal open disableEnforceFocus onClose={handleClose} id="modal-wrapper">
      <div id="modal" className={cn(styles.container)}>
        <ButtonIcon icon={IconName.CLOSE} className={styles.close} onClick={handleClose} />
        <Outlet />
      </div>
    </Modal>
  )
}

export { ModalPage }
