import moment from 'moment'

import { api } from '@/api'

import { TCommissionTier, TTradingVolume } from './types'

const BASE_URL = 'affiliate'

const changeAffiliateName = (newAffiliateId: string) =>
  api.patch(`${BASE_URL}/change-id`, {
    newAffiliateId,
  })

const getMySignupsCount = (fromTimestamp?: string) =>
  api.get<number>(`${BASE_URL}/signups${fromTimestamp ? `?fromTimestamp=${fromTimestamp}` : ''}`)

const getTradingVolume = async (fromTimestamp?: string, timePeriod = '') => {
  const storageKey = `trading-volume-${timePeriod}`

  if (timePeriod) {
    const cachedData = localStorage.getItem(storageKey)
    const parsed: { savedAt: string; data: number } | null = cachedData
      ? JSON.parse(cachedData)
      : null

    // Fetch data at least once in 3 hours
    if (parsed && moment().diff(moment(parsed.savedAt), 'hours') < 3) {
      return parsed.data
    }
  }

  const { data } = await api.get<TTradingVolume[]>(
    `${BASE_URL}/trading-volume${fromTimestamp ? `?fromTimestamp=${fromTimestamp}` : ''}`,
  )

  const tradingVolumeEth = data.reduce(
    (acc, item) => acc + item.hash.reduce((acc, item) => acc + item.eth || 0, 0),
    0,
  )

  if (timePeriod) {
    localStorage.setItem(
      storageKey,
      JSON.stringify({ savedAt: moment().toISOString(), data: tradingVolumeEth }),
    )
  }

  return tradingVolumeEth
}

const getMyCommissionTier = () => api.get<TCommissionTier>(`${BASE_URL}/commission-tier/my`)

export { changeAffiliateName, getMySignupsCount, getTradingVolume, getMyCommissionTier }
