import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'

import { getUserById } from '@/api/user'
import { getUserWalletsFormatted } from '@/api/wallet'
import { TUser } from '@/libs/types'
import { TUserWallet } from '@/libs/types/user.type'

type TInitialState = {
  userData: TUser | null
  userWallets: TUserWallet[] | null
  mainWallet: TUserWallet | null
}

const initialState: TInitialState = {
  userData: null,
  userWallets: null,
  mainWallet: null,
}

const fetchUserByIdWithWallets = createAsyncThunk(
  'user/fetchUserByIdWithWallets',
  async (userId: string) => {
    const [{ data: userData }, userWallets] = await Promise.all([
      getUserById(userId),
      getUserWalletsFormatted(userId),
    ])
    return { userData, userWallets }
  },
)

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (state, { payload }: PayloadAction<TUser>) => {
      state.userData = payload
    },
    setUserWallets: (state, { payload }: PayloadAction<TUserWallet[]>) => {
      state.userWallets = payload
    },
    removeUser: (state) => {
      state.userData = null
      state.userWallets = null
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserByIdWithWallets.fulfilled, (state, { payload }) => {
      state.userData = payload.userData
      state.userWallets = payload.userWallets
      const main = payload.userWallets.find((wallet: TUserWallet) => wallet.isDefault)
      if (main) state.mainWallet = main
    })
  },
})

const { setUser, setUserWallets, removeUser } = userSlice.actions

export {
  userSlice,

  // Reducers
  setUser,
  setUserWallets,
  removeUser,

  // Thunks
  fetchUserByIdWithWallets,
}
