import { useState } from 'react'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'

import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'

import { changeAffiliateName } from '@/api/affiliate'
import { Button, Input, Typography } from '@/libs/common'
import { AppRoute } from '@/libs/enums'
import { useAppDispatch, useAppSelector } from '@/store'
import { fetchUserByIdWithWallets } from '@/store/slices/user.slice'

import styles from './styles.module.scss'

const afiliateIdSchema = yup.object({
  affiliateId: yup
    .string()
    .min(5, 'Affililate name must be at least 5 characters')
    .max(30)
    .required('Enter affiliate name'),
})

const ChangeAffiliateName = () => {
  const user = useAppSelector((state) => state.user.userData)
  const dispatch = useAppDispatch()

  const navigate = useNavigate()

  const [isLoading, setIsLoading] = useState(false)

  const defaultValues = {
    affiliateId: user?.affiliate_id || '',
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues,
    resolver: yupResolver(afiliateIdSchema),
  })

  const onSubmit: SubmitHandler<typeof defaultValues> = async (data) => {
    if (!user) return
    try {
      setIsLoading(true)
      await changeAffiliateName(data.affiliateId.trim())
      await dispatch(fetchUserByIdWithWallets(user._id)).unwrap()
      navigate(AppRoute.DASHBOARD)
    } catch (err) {
      console.error(err)
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className={styles.container}>
      <Typography variant="h1" className={styles.title}>
        Edit your affiliate name
      </Typography>
      <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
        <Controller
          name="affiliateId"
          control={control}
          render={({ field: { ref, ...field } }) => (
            <Input
              error={Boolean(errors.affiliateId)}
              errorMessage={errors.affiliateId?.message}
              maxLength={30}
              isTransparent
              label="Affiliate name"
              placeholder="Enter wallet name"
              {...field}
            />
          )}
        />

        <Button type="submit" isLoading={isLoading}>
          Save
        </Button>
      </form>
    </div>
  )
}

export { ChangeAffiliateName }
