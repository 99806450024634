import { FC } from 'react'

import styles from './styles.module.scss'

type TProperty = {
  href: string
  label: string
  onClick?: () => void
}

const Link: FC<TProperty> = ({ href, label, onClick }) => {
  return (
    <a href={href} className={styles.link} onClick={() => onClick?.()}>
      {label}
    </a>
  )
}

export { Link }
