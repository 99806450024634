import { Grid } from '@mui/material'

import { useAuth } from '@/hooks/auth.hook'
import { Link, Typography } from '@/libs/common'

const NoAccount = () => {
  const { logoutWithRedirect } = useAuth()

  return (
    <Grid height="100vh" alignItems="center" justifyContent="center" display="flex">
      <Grid
        display="flex"
        alignItems="center"
        flexDirection="column"
        paddingRight={2}
        paddingLeft={2}
      >
        <Typography textColor="white" variant="h1">
          No account created
        </Typography>
        <Typography textColor="color-grey-6" marginTop={2} textAlign="center">
          If you want to use the dashboard, you need to create an account first.
          <br />
          To do so, you need to follow the link below and complete the registration.
          <br />
          <Link
            href={import.meta.env.VITE_WEB_URL}
            onClick={logoutWithRedirect}
            label={import.meta.env.VITE_WEB_URL}
          />
          <br />
          Or you can <Link href="" label="change" onClick={logoutWithRedirect} /> the account
        </Typography>
      </Grid>
    </Grid>
  )
}

export { NoAccount }
