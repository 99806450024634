import { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'

import { AppState, Auth0Provider } from '@auth0/auth0-react'

import { App } from '@/components/app'
import history from '@/utils/history'

import './index.scss'
import { store } from './store'

const root = createRoot(document.getElementById('root') as HTMLElement)

// Auth0
const onRedirectCallback = (appState?: AppState) => {
  history.push(appState && appState.returnTo ? appState.returnTo : window.location.pathname)
}

root.render(
  <StrictMode>
    <Provider store={store}>
      <Auth0Provider
        domain={import.meta.env.VITE_AUTH0_DOMAIN}
        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
        onRedirectCallback={onRedirectCallback}
        cacheLocation="localstorage"
        useRefreshTokens
        authorizationParams={{
          redirect_uri: window.location.origin,
          ...(import.meta.env.VITE_AUTH0_AUDIENCE
            ? {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
                scope: import.meta.env.VITE_AUTH0_SCOPE,
              }
            : null),
        }}
      >
        <App />
      </Auth0Provider>
    </Provider>
  </StrictMode>,
)
