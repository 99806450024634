import { api } from '@/api'
import { TUser } from '@/libs/types'

const BASE_URL = 'user'

const getUserById = (userId: string) => api.get<TUser>(`${BASE_URL}/${userId}`)

const getCurrentUser = () => api.get<TUser>(`${BASE_URL}/current`)

export { getUserById, getCurrentUser }
