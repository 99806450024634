import { api } from '@/api'
import { TUserWallet } from '@/libs/types'

import { TGetUserWalletsResponse } from './types'

const BASE_URL = 'wallets'

const getUserWallets = (userId: string) => api.get<TGetUserWalletsResponse>(`${BASE_URL}/${userId}`)

const getUserWalletsFormatted = async (userId: string) => {
  const { data } = await getUserWallets(userId)

  const walletsInfo: TUserWallet[] = data.walletInfo.map((item) => {
    const { _id, ...rest } = item
    return { ...rest, id: _id }
  })

  data.walletBalance.forEach((balanceItem) => {
    balanceItem.wallet_balances.forEach((walletBalance) => {
      const addressLower = walletBalance.address.toLowerCase()
      const currentWallet = walletsInfo.find(
        (infoItem) => infoItem.public_key.toLowerCase() === addressLower,
      )
      if (!currentWallet) return

      currentWallet.balance = walletBalance.balance
      currentWallet.balanceFormatted = walletBalance.balance_formatted
      currentWallet.chain = balanceItem.chain
      currentWallet.chain_id = balanceItem.chain_id
    })
  })

  const sorterByPriority = walletsInfo.sort((a, b) => a.priority - b.priority)

  return sorterByPriority
}

export { getUserWallets, getUserWalletsFormatted }
