import React, { FC } from 'react'

import MuiPopover, { PopoverProps } from '@mui/material/Popover'
import cn from 'classnames'

import stylesClasses from './classes-styles.module.scss'
import styles from './styles.module.scss'

type TOnChangeVisible = {
  visible: boolean
  anchorE: HTMLElement | undefined
}

type TPopoverProps = Omit<PopoverProps, 'open'> & {
  children: React.ReactNode
  onChangeVisible: (state: TOnChangeVisible) => void
  popoverState: TOnChangeVisible
  className?: string
}

const Popover: FC<TPopoverProps> = ({
  popoverState,
  children,
  onChangeVisible,
  className,
  ...props
}) => {
  return (
    <MuiPopover
      classes={stylesClasses}
      open={popoverState.visible ?? false}
      onClose={() => onChangeVisible({ visible: false, anchorE: popoverState.anchorE })}
      anchorEl={popoverState.anchorE}
      {...props}
    >
      <div className={cn(styles.container, className)}>{children}</div>
    </MuiPopover>
  )
}

export { Popover, type TOnChangeVisible, type TPopoverProps }
