import { ComponentProps, FC, useState } from 'react'

import cn from 'classnames'

import { useAuth } from '@/hooks/auth.hook'
import { ButtonIcon } from '@/libs/common/button-icon'
import { Button } from '@/libs/common/button/button'
import { Popover, type TOnChangeVisible } from '@/libs/common/popover'
import { Typography } from '@/libs/common/typography'
import { IconName } from '@/libs/enums'

import styles from './styles.module.scss'

type TProperty = {
  variant?: undefined | 'onboarding'
}

const SignOutButton: FC<ComponentProps<'button'> & TProperty> = ({ variant, ...props }) => {
  const { logoutWithRedirect } = useAuth()
  const [signOutPopup, setSignOutDeletePopup] = useState<TOnChangeVisible>({
    visible: false,
    anchorE: undefined,
  })

  return (
    <div className={styles.container}>
      <ButtonIcon
        className={cn(styles.trigger, {
          [styles[variant ?? '']]: variant,
          [styles.activeButton]: signOutPopup.visible,
        })}
        icon={IconName.POWER}
        tooltipTitle={!variant ? 'Sign out' : undefined}
        onClick={(e) => setSignOutDeletePopup({ visible: true, anchorE: e.currentTarget })}
        {...props}
      >
        {variant &&
          {
            onboarding: <Typography variant="body1">Sign out</Typography>,
          }[variant]}
      </ButtonIcon>

      <Popover
        className={styles.popover}
        popoverState={signOutPopup}
        onChangeVisible={setSignOutDeletePopup}
      >
        <Typography variant="body2" className={styles.title}>
          Sign out
        </Typography>
        <Typography variant="body2">Are you sure you want sign out?</Typography>
        <div className={styles.buttons}>
          <ButtonIcon
            onClick={() => {
              setSignOutDeletePopup({ visible: false, anchorE: signOutPopup.anchorE })
            }}
          >
            <Typography variant="body2" className={styles.cancel}>
              Cancel
            </Typography>
          </ButtonIcon>
          <Button
            className={styles.confirm}
            styleVariant="red"
            onClick={() => {
              logoutWithRedirect()
              setSignOutDeletePopup({ visible: false, anchorE: signOutPopup.anchorE })
            }}
          >
            Sign out
          </Button>
        </div>
      </Popover>
    </div>
  )
}

export { SignOutButton }
