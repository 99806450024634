import axios from 'axios'

type TGeneratorFunc = (params?: any) => Promise<string>
// It's not the best approach, but it's the only way to get access_token outside of a component
let tokenGenerator: TGeneratorFunc | null = null
const setTokenGenerator = (newTokenGenerator: TGeneratorFunc | null) => {
  if (!tokenGenerator) {
    tokenGenerator = newTokenGenerator
  }
}

const api = axios.create({
  baseURL: import.meta.env.VITE_INDEXER_URL,
  headers: {
    Accept: '*/*',
  },
})

api.interceptors.request.use(
  async (config) => {
    const token = (await tokenGenerator?.()) || ''

    if (!config.headers.Authorization && token) {
      config.headers.Authorization = `Bearer ${token}`
    }

    return config
  },
  (error) => {
    Promise.reject(error)
  },
)

export { setTokenGenerator, api }
