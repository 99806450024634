import { FC, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { jwtDecode } from 'jwt-decode'

import { setTokenGenerator } from '@/api'
import { useAuth } from '@/hooks/auth.hook'
import { AppRoute } from '@/libs/enums'
import { useAppDispatch } from '@/store'
import { fetchUserByIdWithWallets } from '@/store/slices/user.slice'

type TProps = {
  setIsAppLoaded: (value: boolean) => void
}

const AuthController: FC<TProps> = ({ setIsAppLoaded }) => {
  const navigate = useNavigate()
  const dispatch = useAppDispatch()

  const { isAuthenticated, isLoading, loginWithRedirect, getAccessTokenSilently } = useAuth()

  const handleAuth = async (): Promise<void> => {
    if (isLoading) {
      return
    }

    if (!isAuthenticated) {
      loginWithRedirect()
    } else {
      let token: string | null = null

      try {
        token = await getAccessTokenSilently()
        setTokenGenerator(getAccessTokenSilently as any)
      } catch (err) {
        const message: string = (err as any).message
        if (message?.includes('Missing Refresh Token')) {
          await new Promise((resolve) => setTimeout(resolve, 200))
          return handleAuth()
        }
      }

      if (!token) {
        return
      }

      const decoded = jwtDecode(token)
      const userId = decoded?.sub as string

      try {
        await dispatch(fetchUserByIdWithWallets(userId)).unwrap()
        navigate(AppRoute.DASHBOARD)
      } catch (err) {
        navigate(AppRoute.NO_ACCOUNT_CREATED)
      }

      setIsAppLoaded(true)
    }
  }

  useEffect(() => {
    handleAuth()
  }, [isAuthenticated, isLoading])

  return <></>
}

export { AuthController }
