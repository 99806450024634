import { FC, PropsWithChildren, useRef, useState } from 'react'

import { Typography } from '@mui/material'
import cn from 'classnames'

import Logo from '@/assets/images/logo.svg'
import { SignOutButton } from '@/libs/common'
import { hideWalletAddress } from '@/libs/helpers'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

type TProps = PropsWithChildren

const Sidebar: FC<TProps> = ({ children }) => {
  const mainWallet = useAppSelector((state) => state.user.mainWallet)

  const [isCollapsed, setIsCollapsed] = useState(true)
  const sidebarRef = useRef(null)

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed)
  }

  return (
    <div className={cn(styles.container, { [styles.collapsed]: isCollapsed })}>
      <nav className={styles.header}>
        <img src={Logo} alt="Blazing bot" />
        <button className={cn(styles.burgerBtn)} type="button" onClick={handleToggle}>
          <span />
        </button>
      </nav>
      <div
        className={styles.sidebarOverlay}
        onClick={(e) => {
          e.stopPropagation()
          setIsCollapsed(true)
        }}
      />
      <div className={styles.sidebar} ref={sidebarRef}>
        <div className={styles.logo}>
          <a href="/">
            <img src={Logo} alt="Blazing bot" className="img-fluid" />
          </a>
        </div>
        <div className={styles.wallet}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            className={styles.walletIcon}
          >
            <path
              d="M4.99997 17.9999V19V4.99998V17.9999ZM5.3077 20.5C4.80898 20.5 4.38302 20.3233 4.02982 19.9701C3.67661 19.6169 3.5 19.191 3.5 18.6923V5.3077C3.5 4.80898 3.67661 4.38302 4.02982 4.02982C4.38302 3.67661 4.80898 3.5 5.3077 3.5H18.6923C19.191 3.5 19.6169 3.67661 19.9701 4.02982C20.3233 4.38302 20.5 4.80898 20.5 5.3077V8.02885H19V5.3077C19 5.21795 18.9711 5.14423 18.9134 5.08653C18.8557 5.02883 18.782 4.99998 18.6923 4.99998H5.3077C5.21795 4.99998 5.14423 5.02883 5.08653 5.08653C5.02883 5.14423 4.99997 5.21795 4.99997 5.3077V18.6923C4.99997 18.782 5.02883 18.8557 5.08653 18.9134C5.14423 18.9711 5.21795 19 5.3077 19H18.6923C18.782 19 18.8557 18.9711 18.9134 18.9134C18.9711 18.8557 19 18.782 19 18.6923V15.9711H20.5V18.6923C20.5 19.191 20.3233 19.6169 19.9701 19.9701C19.6169 20.3233 19.191 20.5 18.6923 20.5H5.3077ZM13.3077 16.5C12.809 16.5 12.383 16.3233 12.0298 15.9701C11.6766 15.6169 11.5 15.191 11.5 14.6923V9.3077C11.5 8.80898 11.6766 8.38303 12.0298 8.02983C12.383 7.67661 12.809 7.5 13.3077 7.5H19.6922C20.191 7.5 20.6169 7.67661 20.9701 8.02983C21.3233 8.38303 21.5 8.80898 21.5 9.3077V14.6923C21.5 15.191 21.3233 15.6169 20.9701 15.9701C20.6169 16.3233 20.191 16.5 19.6922 16.5H13.3077ZM19.6922 15C19.782 15 19.8557 14.9711 19.9134 14.9134C19.9711 14.8557 20 14.782 20 14.6923V9.3077C20 9.21795 19.9711 9.14423 19.9134 9.08653C19.8557 9.02883 19.782 8.99998 19.6922 8.99998H13.3077C13.218 8.99998 13.1442 9.02883 13.0865 9.08653C13.0288 9.14423 13 9.21795 13 9.3077V14.6923C13 14.782 13.0288 14.8557 13.0865 14.9134C13.1442 14.9711 13.218 15 13.3077 15H19.6922ZM16 13.5C16.4166 13.5 16.7708 13.3541 17.0625 13.0625C17.3541 12.7708 17.5 12.4166 17.5 12C17.5 11.5833 17.3541 11.2291 17.0625 10.9375C16.7708 10.6458 16.4166 10.5 16 10.5C15.5833 10.5 15.2291 10.6458 14.9375 10.9375C14.6458 11.2291 14.5 11.5833 14.5 12C14.5 12.4166 14.6458 12.7708 14.9375 13.0625C15.2291 13.3541 15.5833 13.5 16 13.5Z"
              fill="#FFC800"
            />
          </svg>
          <Typography marginLeft={2}>
            {mainWallet?.public_key ? hideWalletAddress(mainWallet.public_key) : 'No main wallet'}
          </Typography>
        </div>
        <div className={styles.menu}>
          <ul className={styles.list}>
            <li>
              <a href="#!" className={cn(styles.linkItem, styles.active)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M13.25 8.99998V3.5H20.5V8.99998H13.25ZM3.5 12.5V3.5H10.75V12.5H3.5ZM13.25 20.5V11.5H20.5V20.5H13.25ZM3.5 20.5V15H10.75V20.5H3.5ZM4.99997 11H9.25V4.99998H4.99997V11ZM14.75 19H19V13H14.75V19ZM14.75 7.5H19V4.99998H14.75V7.5ZM4.99997 19H9.25V16.5H4.99997V19Z"
                    fill="#FFC800"
                  />
                </svg>
                Overview
              </a>
            </li>
            {/* <li>
                            <a href="#!" className="">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                    <path d="M5.3077 20.5C4.80257 20.5 4.375 20.325 4.025 19.975C3.675 19.625 3.5 19.1974 3.5 18.6923V5.3077C3.5 4.80257 3.675 4.375 4.025 4.025C4.375 3.675 4.80257 3.5 5.3077 3.5H18.6923C19.1974 3.5 19.625 3.675 19.975 4.025C20.325 4.375 20.5 4.80257 20.5 5.3077V18.6923C20.5 19.1974 20.325 19.625 19.975 19.975C19.625 20.325 19.1974 20.5 18.6923 20.5H5.3077ZM5.3077 19H18.6923C18.7692 19 18.8397 18.9679 18.9038 18.9038C18.9679 18.8397 19 18.7692 19 18.6923V5.3077C19 5.23077 18.9679 5.16024 18.9038 5.09613C18.8397 5.03203 18.7692 4.99998 18.6923 4.99998H5.3077C5.23077 4.99998 5.16024 5.03203 5.09612 5.09613C5.03202 5.16024 4.99997 5.23077 4.99997 5.3077V18.6923C4.99997 18.7692 5.03202 18.8397 5.09612 18.9038C5.16024 18.9679 5.23077 19 5.3077 19ZM6.75003 16.75H17.3269L14.0384 12.3654L11.2308 16.0192L9.23075 13.4616L6.75003 16.75ZM8.49998 9.74995C8.84613 9.74995 9.14099 9.62816 9.38458 9.38458C9.62816 9.14099 9.74995 8.84613 9.74995 8.49998C9.74995 8.15383 9.62816 7.85896 9.38458 7.61538C9.14099 7.37179 8.84613 7.25 8.49998 7.25C8.15383 7.25 7.85896 7.37179 7.61538 7.61538C7.37179 7.85896 7.25 8.15383 7.25 8.49998C7.25 8.84613 7.37179 9.14099 7.61538 9.38458C7.85896 9.62816 8.15383 9.74995 8.49998 9.74995Z" fill="#C8C8C8" />
                                </svg>
                                Assets
                            </a>
                        </li> */}
          </ul>
          {/* <div className={styles.helpContainer}>
            <div>
              <Typography textColor="color-white-2">Need help?</Typography>
              <Typography marginBottom={2}>Contact your account manager</Typography>
              <Button className={styles.contactBtn}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  className="icon me-2"
                >
                  <path
                    d="M2.42894 9.84017C7.6656 7.53642 14.4423 4.72767 15.3777 4.33892C17.8314 3.32142 18.5844 3.51642 18.2089 5.76976C17.9394 7.38934 17.1614 12.7514 16.5414 16.0889C16.1735 18.0681 15.3481 18.3027 14.0502 17.4464C13.426 17.0343 10.2756 14.951 9.59185 14.4618C8.96769 14.016 8.10685 13.4798 9.18644 12.4235C9.5706 12.0473 12.0889 9.64267 14.051 7.77101C14.3081 7.52517 13.9852 7.12142 13.6885 7.31851C11.0439 9.07226 7.37727 11.5064 6.9106 11.8235C6.2056 12.3023 5.52852 12.5218 4.3131 12.1727C3.39477 11.9089 2.49769 11.5943 2.14852 11.4743C0.803937 11.0127 1.1231 10.4148 2.42894 9.84017Z"
                    fill="#0e0e0e"
                  />
                </svg>
                <Typography textColor="color-black-15" fontWeight={700}>
                  Joseph.park
                </Typography>
              </Button>
            </div>
          </div> */}
          <div className={styles.signOut}>
            <SignOutButton />
          </div>
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  )
}

export { Sidebar }
