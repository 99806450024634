import { FC, useEffect, useRef, useState } from 'react'

import cn from 'classnames'

import { Spinner } from '@/components/spinner'
import { Typography } from '@/libs/common'
import { SpinnerSize } from '@/libs/enums'

import { TTabItem } from './libs/types'
import styles from './styles.module.scss'

type TProps = {
  tabs: TTabItem[]
  activeTab: TTabItem
  setActiveTab: (tab: TTabItem) => any
  isLoading?: boolean
}

const Tabs: FC<TProps> = ({ tabs, activeTab, setActiveTab, isLoading }) => {
  const [sliderWidth, setSliderWidth] = useState(0)
  const [sliderOffset, setSliderOffset] = useState(0)

  const navTabsRef = useRef<HTMLDivElement | null>(null)

  const handleTabClick = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    itemId: string | number,
  ) => {
    if (isLoading) return

    const newActiveContent = tabs.find((item) => item.id === itemId)

    if (!newActiveContent) return

    setActiveTab(newActiveContent)

    const activeLinkElement = e.currentTarget

    if (activeLinkElement) {
      updateSlider(activeLinkElement)
    }
  }

  const updateSlider = (activeLinkElement: HTMLButtonElement | HTMLDivElement) => {
    const rect = activeLinkElement.getBoundingClientRect()
    setSliderWidth(rect.width)
    setSliderOffset(activeLinkElement.offsetLeft)
  }

  useEffect(() => {
    if (!navTabsRef.current) return

    const defaultIndex = tabs.findIndex((item) => item.id === activeTab.id)
    const defaultElement = navTabsRef.current.querySelector(
      `button:nth-of-type(${defaultIndex + 1})`,
    )

    if (defaultElement) updateSlider(defaultElement as HTMLButtonElement)
  }, [])

  return (
    <div className={styles.container} ref={navTabsRef}>
      <div className={styles.slider} style={{ width: sliderWidth, left: sliderOffset }}>
        <div className={cn(styles.loaderContainer, { [styles.visible]: isLoading })}>
          <Spinner size={SpinnerSize.SMALL} centered />
        </div>
      </div>
      {tabs.map((item) => (
        <button
          key={item.id}
          data-id={item.id}
          className={cn(styles.item, { [styles.active]: activeTab.id === item.id })}
          onClick={(e) => handleTabClick(e, item.id)}
        >
          <Typography
            textColor="white"
            className={cn(styles.name, { [styles.loading]: isLoading && activeTab.id === item.id })}
          >
            {item.name}
          </Typography>
        </button>
      ))}
    </div>
  )
}

export { Tabs }
