import { FC } from 'react'

import cn from 'classnames'

import { SpinnerSize } from '@/libs/enums'

import styles from './styles.module.scss'

type TProps = {
  absoluteCentered?: boolean
  size?: SpinnerSize | number
  centered?: boolean
}

const Spinner: FC<TProps> = ({ size = SpinnerSize.BIG, absoluteCentered = false, centered }) => {
  return centered ? (
    <div className={styles.spinnerWrapper}>
      <div>
        <div className={cn(styles.container)} style={{ width: size, height: size }}>
          <div className={styles.loader} style={{ minWidth: size, minHeight: size }}></div>
        </div>
      </div>
    </div>
  ) : (
    <div
      className={cn(styles.container, { [styles.absoluteCentered]: absoluteCentered })}
      style={{ width: size, height: size }}
    >
      <div className={styles.loader} style={{ minWidth: size, minHeight: size }}></div>
    </div>
  )
}

export { Spinner }
