import { FC, useEffect } from 'react'
import { Route, Routes } from 'react-router-dom'

import { CssBaseline, StyledEngineProvider } from '@mui/material'

import { AppRoute } from '@/libs/enums'
import { Dashboard } from '@/pages/dashboard'
import { ModalPage } from '@/pages/modal-page'
import { ChangeAffiliateName } from '@/pages/modal-page/libs/components/change-affiliate-name'
import { NoAccount } from '@/pages/no-account'
import { Root } from '@/pages/root'

const Layout: FC = () => {
  useEffect(() => {
    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox')
    if (isFirefox) {
      document.querySelector('html')?.classList.add('firefox-styles')
    }
  }, [])

  return (
    <StyledEngineProvider injectFirst>
      <CssBaseline />

      <div>
        <Routes>
          <Route path={AppRoute.ROOT} element={<Root />} />

          <Route path={AppRoute.NO_ACCOUNT_CREATED} element={<NoAccount />} />

          <Route path={AppRoute.DASHBOARD} element={<Dashboard />}>
            <Route path={AppRoute.MODAL} element={<ModalPage />}>
              <Route path={AppRoute.CHANGE_AFFILIATE_NAME} element={<ChangeAffiliateName />} />
            </Route>
          </Route>
        </Routes>
      </div>
    </StyledEngineProvider>
  )
}

export { Layout }
