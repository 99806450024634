import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Grid } from '@mui/material'
import cn from 'classnames'
import moment from 'moment'

import { getMyCommissionTier, getMySignupsCount, getTradingVolume } from '@/api/affiliate'
import { TCommissionTier } from '@/api/affiliate/types'
import { Icon, Typography } from '@/libs/common'
import { GreySectionWithHeading } from '@/libs/common/grey-section-with-heading'
import { InfoItemWithLabel } from '@/libs/common/info-item-with-label'
import { Tabs } from '@/libs/common/tabs'
import { TTabItem } from '@/libs/common/tabs/libs/types'
import { AppRoute, IconName } from '@/libs/enums'
import { formatTokenPrice } from '@/libs/helpers'
import { useAppSelector } from '@/store'

import styles from './styles.module.scss'

const periodTabs: TTabItem[] = [
  { id: 1, name: '24H' },
  { id: 2, name: '7D' },
  { id: 3, name: '1M' },
  { id: 4, name: '1Y' },
  { id: 5, name: 'ALL' },
]

const Overview = () => {
  const user = useAppSelector((state) => state.user.userData)

  const [activeTab, setActiveTab] = useState(periodTabs[periodTabs.length - 1])
  const [isOverviewLoading, setIsOverviewLoading] = useState(false)
  const [signupsCount, setSignupsCount] = useState(0)
  const [tradingVolume, setTradingVolume] = useState(0)
  const [commissionTier, setCommissionTier] = useState<TCommissionTier | null>(null)

  const navigate = useNavigate()

  const webInviteLink = `${import.meta.env.VITE_WEB_URL}?affiliateId=${user?.affiliate_id}`
  const tgInviteLink = `${import.meta.env.VITE_TG_URL}?start=${user?.affiliate_id}`

  const handleSignups = async (period?: string) => {
    setIsOverviewLoading(true)

    const periodToUse = period || activeTab.name
    let fromTimestamp: moment.Moment | null = moment()

    switch (periodToUse) {
      case '24H':
        fromTimestamp.subtract(1, 'days')
        break
      case '7D':
        fromTimestamp.subtract(7, 'days')
        break
      case '1M':
        fromTimestamp.subtract(1, 'months')
        break
      case '1Y':
        fromTimestamp.subtract(1, 'years')
        break
      case 'ALL':
        fromTimestamp = null
        break
      default:
        fromTimestamp = null
        break
    }

    try {
      const fromTimestampString = fromTimestamp?.valueOf().toString()
      const [{ data: signup }, tradingVolumeEth] = await Promise.all([
        getMySignupsCount(fromTimestampString),
        getTradingVolume(fromTimestampString, periodToUse),
      ])
      setTradingVolume(tradingVolumeEth)
      setSignupsCount(signup)
    } catch (err) {
      console.error(err)
    } finally {
      setIsOverviewLoading(false)
    }
  }

  const handleRename = () => {
    navigate(`${AppRoute.DASHBOARD}/${AppRoute.MODAL}/${AppRoute.CHANGE_AFFILIATE_NAME}`)
  }

  const handleCommissionTier = async () => {
    const { data } = await getMyCommissionTier()
    setCommissionTier(data)
  }

  const handleTabChange = async (tab: TTabItem) => {
    setActiveTab(tab)
    handleSignups(tab.name)
  }

  useEffect(() => {
    handleSignups()
    handleCommissionTier()
  }, [])

  return (
    <Grid className={styles.container}>
      <Typography textColor="color-white" variant="h1">
        My Dashboard
      </Typography>

      <GreySectionWithHeading
        title="Account Overview"
        headerAddition={
          <Tabs
            tabs={periodTabs}
            activeTab={activeTab}
            setActiveTab={handleTabChange}
            isLoading={isOverviewLoading}
          />
        }
        gap={3}
        flexDirection="row"
        display="flex"
        justifyContent="space-between"
        className={styles.infoSection}
        contentClassName={cn(styles.signupsWrapper, styles.infoSection)}
        headerClassName={styles.tabsWrapper}
      >
        <InfoItemWithLabel title="No. of Signups:" info={`${signupsCount}`} />
        <InfoItemWithLabel
          title="Referrals Trading Volume"
          info={<>{formatTokenPrice(`${tradingVolume}`).formatted} ETH</>}
        />
      </GreySectionWithHeading>

      <GreySectionWithHeading
        title="My Promotion Tools"
        gap={3}
        display="flex"
        flexDirection="column"
        contentClassName={styles.infoSection}
      >
        <InfoItemWithLabel
          title="Commission Tier"
          info={commissionTier ? `${commissionTier.name} - ${commissionTier.percent}%` : '-'}
        />
        <InfoItemWithLabel
          title="My affiliate name"
          info={user?.affiliate_id}
          infoAttachment={
            <div className={styles.affiliateName} onClick={handleRename}>
              <Icon name={IconName.EDIT_SMALL} />
            </div>
          }
        />
        <InfoItemWithLabel
          title="Web Referral Code"
          info={webInviteLink}
          withCopy
          infoClassName={styles.referralCode}
        />
        <InfoItemWithLabel
          title="Telegram Referral Code"
          info={tgInviteLink}
          withCopy
          infoClassName={styles.referralCode}
        />
      </GreySectionWithHeading>
    </Grid>
  )
}

export { Overview }
