import { FC, PropsWithChildren } from 'react'

import { Grid } from '@mui/material'
import { DefaultComponentProps, OverridableTypeMap } from '@mui/material/OverridableComponent'
import cn from 'classnames'

import { Typography } from '@/libs/common'

import styles from './styles.module.scss'

type TProps = PropsWithChildren &
  DefaultComponentProps<OverridableTypeMap> & {
    title: string
    headerAddition?: React.ReactNode
    className?: string
    contentClassName?: string
    headerClassName?: string
  }

const GreySectionWithHeading: FC<TProps> = ({
  title,
  className,
  contentClassName,
  headerClassName,
  headerAddition,
  children,
  ...rest
}) => {
  return (
    <Grid className={cn(styles.container, className)}>
      <Grid className={cn(styles.header, headerClassName)}>
        <Typography textColor="color-white" variant="h2" className={styles.title}>
          {title}
        </Typography>
        {headerAddition}
      </Grid>
      <Grid className={cn(styles.content, contentClassName)} {...rest}>
        {children}
      </Grid>
    </Grid>
  )
}

export { GreySectionWithHeading }
