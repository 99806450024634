import { useAuth0 } from '@auth0/auth0-react'

export const useAuth = () => {
  const { logout, ...rest } = useAuth0()

  const logoutWithRedirect = (url = window.location.origin) =>
    logout({
      logoutParams: {
        returnTo: url,
      },
    })

  return {
    ...rest,
    logout,
    logoutWithRedirect,
  }
}
